import { setCanary } from './microfronts/microfrontsHosts';
import getTenant, { type MinimumTenant } from '@/security/getTenant';
const currentScriptSrc = document.currentScript?.['src'];
const CANARY_SCRIPT = 'https://canary.niara.tech/static/js/index.js';
const CANARY_SCRIPT_2 = 'https://canary.niara.tech/static/js/index2.js';
const main = async () => {
  function loadJS(url, location) {
    const scriptTag = document.createElement('script');
    scriptTag.src = url;
    scriptTag.crossOrigin = 'anonymous';
    scriptTag.defer = true;
    scriptTag.async = true;
    location.appendChild(scriptTag);
  }
  if (currentScriptSrc == CANARY_SCRIPT) {
    loadJS(CANARY_SCRIPT_2, document.body);
    setCanary(true);
    return;
  }

  // carrega o tenant.canary
  getTenant().then((tenant: MinimumTenant) => {
    if (tenant?.canaryStartDate && tenant?.canaryEndDate) {
      const currentDate = new Date().toISOString();
      if (currentDate >= tenant.canaryStartDate && currentDate <= tenant.canaryEndDate) {
        loadJS(CANARY_SCRIPT, document.body);
        setCanary(true);
      }
    } else if (process.env.WEBPACK_SERVE) {
      import('./index2');
    } else {
      loadJS('/static/js/index2.js', document.body);
    }
  });
};
main();