const REGULAR_DOMAIN_REGEX = process.env.NODE_ENV === 'development' ? `front\\.omnibees\\.com|cr\\.omnibees\\.com|(?:hotfix|dev|next|prehomolog)(?:\\.admin|\\.omnibees)*\\.niara\\.tech|(?:[a-z0-9]{1,})\\.cloudfront\\.net|localhost|127\\.0\\.0\\.1` : `front\\.omnibees\\.com|cr\\.omnibees\\.com|(?:hotfix|dev|next|prehomolog)(?:\\.admin|\\.omnibees)*\\.niara\\.tech|(?:[a-z0-9]{1,})\\.cloudfront\\.net`;

/**
 * @deprecated
 */
export const IS_REGULAR_DOMAIN_HOSTNAME = new RegExp(`^(?:${REGULAR_DOMAIN_REGEX})$`);
export const IS_ADMIN_DOMAIN_HOSTNAME = process.env.NODE_ENV === 'development' ? /^127\.0\.0\.1$/ : /\.admin\.niara\.tech$/;
export const IS_OMNIBEES_ADMIN_DOMAIN_HOSTNAME = /\.omnibees\.niara\.tech$/;

/**
 * @deprecated
 */
export const IS_TENANT_ID_HOSTNAME = /^([a-z0-9]{1,})(?:\.admin|\.omnibees|\.dev)*\.niara\.tech$/;
/**
 * @deprecated
 */
export const IS_CHECKOUT_HOSTNAME = /^checkout(?:localhost|dev|homolog|hotfix)*\.niara\.tech$/;
export const IS_HOMOLOG = new RegExp(`^(?:${['[a-z0-9\\-]{0,}homolog[a-z0-9\\-]{0,}(?:\\.admin|\\.omnibees)*\\.niara\\.tech'].join('|')})$`);
export const IS_HOTFIX = new RegExp(`^(?:${['hotfix(?:\\.admin|\\.omnibees)*\\.niara\\.tech'].join('|')})$`);
const TENANT_ID_OR_ALIAS_REGEX = new RegExp(`(?:^(?:${REGULAR_DOMAIN_REGEX})\\/([a-zA-Z0-9%-:]{1,})|^([a-z0-9]{1,})(?:\\.admin|\\.omnibees|\\.dev)*\\.niara\\.tech\\/)`);
export const getLocationAliasOrId = (hostname?: string): string => {
  const text = [hostname ?? window.location.hostname, window.location.pathname.split('/')[1] || ''].join('/');
  const regexResult = TENANT_ID_OR_ALIAS_REGEX.exec(text);
  const x = regexResult?.[1] || regexResult?.[2] || undefined;
  if (x != null && x?.indexOf('%') >= 0) {
    return decodeURIComponent(x);
  }
  return x;
};
export const isTenantIdInPath = (hostname?: string): boolean => {
  return !IS_TENANT_ID_HOSTNAME.test(hostname ?? window.location.hostname) || IS_REGULAR_DOMAIN_HOSTNAME.test(hostname ?? window.location.hostname);
};