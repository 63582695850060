import { IS_HOMOLOG, IS_HOTFIX } from '../hostnames';

// Em ambiente localhost, abrir com https://localhost/develop/hotels3/#niarab2c_dontuselocalhost
// para usar o admin-front e o front unificado em app.niarab2cdev.niara.tech, não no localhost.
// Também pode persistir no localStorage: localStorage.setItem('niarab2c_dontuselocalhost', 'true')
const forceDontUseLocalhost = process.env.NODE_ENV === 'development' && (location?.hash?.indexOf('niarab2c_dontuselocalhost') >= 0 || localStorage.getItem('niarab2c_dontuselocalhost') == 'true');

// Em ambiente develop.niara.tech, abrir com https://develop.niara.tech/hotels3/#niarab2c_uselocalhost
// para usar o admin-front e o front unificado em localhost.
// Também pode persistir no localStorage: localStorage.setItem('niarab2c_uselocalhost', 'true')
const forceUseLocalhost = process.env.DEV_OR_HOMOLOG_OR_PROD === 'dev' && (location?.hash?.indexOf('niarab2c_uselocalhost') >= 0 || localStorage.getItem('niarab2c_uselocalhost') == 'true');
const niarab2c_admin_front_host = process.env.NODE_ENV === 'development' && (location.hostname == 'localhost' || location.hostname == '127.0.0.1') && !forceDontUseLocalhost || forceUseLocalhost ? 'localhost:13000' : process.env.DEV_OR_HOMOLOG_OR_PROD === 'dev' ? 'admin.niarab2cdev.niara.tech' : IS_HOMOLOG.test(location.host) ? 'admin.niarab2chomolog.niara.tech' : 'admin.otabuilder.com';
const niarab2c_admin_front_url = `https://${niarab2c_admin_front_host}/static/js/niarab2c_admin_front.js`;
export { niarab2c_admin_front_url };
const setCanary = (value: boolean) => {
  window['NIARA_IS_CANARY'] = value;
};
export { setCanary };
const get_niarab2c_core_reservations_front_url = () => {
  const isCanary = window['NIARA_IS_CANARY'];
  const niarab2c_core_reservations_front_host = forceUseLocalhost ? 'localhost:13004' : isCanary ? 'canary.core-reservations.niara.tech' : process.env.NODE_ENV === 'development' && (location.hostname == 'localhost' || location.hostname == '127.0.0.1') && !forceDontUseLocalhost ? 'localhost:13004' : process.env.DEV_OR_HOMOLOG_OR_PROD === 'dev' ? 'core-reservations-dev.niara.tech' : IS_HOMOLOG.test(location.host) ? 'core-reservations-homolog.niara.tech' : IS_HOTFIX.test(location.host) ? 'pre-app.core-reservations.niara.tech' : 'core-reservations.niara.tech';
  const niarab2c_core_reservations_front_url = `https://${niarab2c_core_reservations_front_host}/static/js/niarab2c_core_reservations_front.js`;
  return niarab2c_core_reservations_front_url;
};
export { get_niarab2c_core_reservations_front_url };